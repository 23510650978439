<template>
  <!-- 放款金额占比模块 -->
  <div class="left_bottom">
    <title-name-slot title="放款金额占比"></title-name-slot>
    <!-- 图表数据 -->
    <div class="statistics chartsContent" id="echarts"></div>
  </div>
</template>
<script>
import { statisticsApi } from '@/api/homeApi'
import titleNameSlot from './title-name-slot.vue'
export default {
  name: 'loanMoneyProportion',
  components: { titleNameSlot },
  data () {
    return {
      loanplanData: [],
      loanAmountItem: {},
      myChart: ''
    }
  },
  computed: {
    api () {
      return statisticsApi
    }
  },
  mounted () {
    this.loanplanStatistics()
  },
  activated () {
    this.loanplanStatistics()
  },

  methods: {
    // 放款金额占比数据获取
    loanplanStatistics () {
      this.api.loanplanStatistics().then((res) => {
        if (res.success) {
          this.loanplanData = res.data
          this.setEcharts()
        }
      })
    },
    // 放款金额占比
    setEcharts () {
      const that = this
      // 对数据进行转换
      this.loanplanData.map((it) => {
        it.value = it.loanAmount
        if (it.loanAmount.toFixed().length > 8) {
          it.showValue = (parseFloat(it.loanAmount) / 100000000).toFixed(2)
          it.unit = '亿元'
        } else if (it.loanAmount.toFixed().length > 4) {
          it.showValue = (parseFloat(it.loanAmount) / 10000).toFixed(2)
          it.unit = '万元'
        } else {
          it.showValue = it.loanAmount
          it.unit = '元'
        }
        it.name = it.capitalSideName + ' ' + it.proportion + '% ' + it.showValue + it.unit
        return it
      })
      if (this.loanplanData.length === 0) {
        this.loanplanData = [{ value: 0, name: '暂无数据', loanAmount: 0, proportion: 0 }]
      }
      var elementResizeDetectorMaker = require('element-resize-detector')// 引入监听dom变化的组件
      var erd = elementResizeDetectorMaker()
      var worldMapContainer = document.getElementById('echarts') // 获取 图标容器dom元素
      worldMapContainer.style.width = worldMapContainer.scrollWidth + 'px'
      worldMapContainer.style.height = worldMapContainer.clientHeight + 'px'
      // this.myChart = this.$echarts.init(document.getElementById('echarts'))
      this.myChart = this.$echarts.init(worldMapContainer)
      console.log(this.loanplanData, 'loanplanData')
      that.loanAmountItem = {
        proportion: this.loanplanData[0] ? this.loanplanData[0].proportion || 0 : '',
        loanAmount: this.loanplanData[0] ? this.loanplanData[0].value || 0 : '',
        showValue: this.loanplanData[0] ? this.loanplanData[0].showValue || 0 : '',
        unit: this.loanplanData[0] ? this.loanplanData[0].unit || '' : ''
      }
      // 鼠标移动到图例名称时
      this.myChart.on('highlight', (params) => {
        const arr = params.name.split(' ')
        const op = this.myChart.getOption()
        const _label = {
          normal: {
            show: true,
            position: 'center',
            formatter: [
              `{a|${arr[1] || ''}}`,
              `{b|${arr[2] || ''}}`
            ].join('\n'),
            rich: {
              a: {
                color: '#CCABDB;',
                fontSize: 16,
                lineHeight: 30
              },
              b: {
                color: '#CCABDB;',
                fontSize: 14,
                lineHeight: 30
              }
            }
          }
        }
        op.series[0].label = _label
        this.myChart.setOption(op, true)
        erd.listenTo(worldMapContainer, function (element) { // 执行监听
          that.$nextTick(function () {
            op.series[0].label = _label
          })
        })
      })
      // 鼠标移动到饼图上时
      this.myChart.on('mouseover', (params) => {
        if (
          params.data.capitalSideName !== 'circle' &&
          params.seriesIndex === 0
        ) {
          const op = this.myChart.getOption()
          const _label = {
            normal: {
              show: true,
              position: 'center',
              formatter: [
                `{a|${(params.data.proportion || 0) + '%'}}`,
                `{b|${(params.data.showValue || 0) + (params.data.unit || '')}}`
              ].join('\n'),
              rich: {
                a: {
                  color: '#CCABDB;',
                  fontSize: 16,
                  lineHeight: 30
                },
                b: {
                  color: '#CCABDB;',
                  fontSize: 14,
                  lineHeight: 30
                }
              }
            }
          }
          op.series[0].label = _label
          this.myChart.setOption(op, true)
          erd.listenTo(worldMapContainer, function (element) { // 执行监听
            that.$nextTick(function () {
              op.series[0].label = _label
            })
          })
        } else {
          return false
        }
      })
      this.option = {
        // 自定义颜色
        color: [
          '#CCABDB',
          '#D1E6A6',
          '#85E4CD',
          '#FA887A',
          '#FFDE95',
          '#ff5555',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585'
        ],
        legend: {
          icon: 'circle',
          orient: 'vertical',
          top: '0px',
          right: '20px',
          color: '#6D7780',
          textStyle: {
            color: '#6D7780',
            fontSize: 12
          }
        },
        // 饼图配置
        series: [
          {
            name: 'decoration',
            type: 'pie',
            radius: ['60%', '75%'],
            avoidLabelOverlap: false,
            center: ['23%', '52%'],
            hoverAnimation: true,
            label: {
              normal: {
                show: true,
                position: 'center',
                formatter: [
                  `{a|${(that.loanAmountItem.proportion || 0) + '%'}}`,
                  `{b|${(that.loanAmountItem.showValue || 0) + (that.loanAmountItem.unit || '')}}`
                ].join('\n'),
                rich: {
                  a: {
                    color: '#CCABDB;',
                    fontSize: 16,
                    lineHeight: 30
                  },
                  b: {
                    color: '#CCABDB;',
                    fontSize: 14,
                    lineHeight: 30
                  }
                }
              }
            },
            // labelLine: {
            //   normal: {
            //     show: false
            //   }
            // },
            data: this.loanplanData
          },
          {
            name: 'decorationTwo',
            type: 'pie',
            color: ['rgba(115, 132, 246, .67)', 'rgba(255,255,255,0)'],
            center: ['23%', '52%'],
            radius: ['55%', '53%'],
            hoverAnimation: false,
            label: {
              normal: {
                show: false,
                formatter: [
                  `{a|${(that.loanAmountItem.proportion || 0) + '%'}}`,
                  `{b|${(that.loanAmountItem.loanAmount || 0) + (that.loanAmountItem.unit || '')}}`
                ].join('\n'),
                rich: {
                  a: {
                    color: '#CCABDB;',
                    fontSize: 16,
                    lineHeight: 30
                  },
                  b: {
                    color: '#CCABDB;',
                    fontSize: 14,
                    lineHeight: 30
                  }
                }
              },
              emphasis: {
                show: false
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: new Array(102).fill(10).map(() => {
              return {
                name: '',
                value: 10,
                capitalSideName: 'circle',
                loanAmount: that.loanAmountItem.loanAmount,
                proportion: that.loanAmountItem.proportion
              }
            })
          }
        ]
      }
      this.myChart.setOption(this.option)
      erd.listenTo(worldMapContainer, function (element) { // 执行监听
        that.$nextTick(function () {
          this.myChart.setOption(this.option)
        })
      })
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
